import {JsonConverter, JsonCustomConvert, JsonObject, JsonProperty} from "json2typescript";
import OrderDetail from "@/models/OrderDetail";
import Auditing from "@/models/Auditing";
import Address, {AddressType} from "@/models/Address";
import Client from "@/models/Client";
import Company from "@/models/Company";

export enum OrderType {
    WAITING = "WAITING",
    SEND = "SEND",
    FINISH = "FINISH",
    DRAFT = "DRAFT",
}

@JsonConverter
class OrderConverter implements JsonCustomConvert<OrderType> {
    deserialize(data: string): OrderType { return (<any>OrderType)[data] }
    serialize(data: OrderType): any { return data.toString() }
}

@JsonObject("Order")
export default class Order extends Auditing {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("connectorId", String, true)
    connectorId?: string = undefined
    @JsonProperty("reference", String, true)
    reference?: string = undefined
    @JsonProperty("description", String, true)
    description?: string = undefined
    @JsonProperty("orderDetails", [OrderDetail], true)
    orderDetails?: OrderDetail[] = []
    @JsonProperty("orderType", OrderConverter, true)
    orderType?: OrderType = undefined
    @JsonProperty("billingAddress", Address, true)
    billingAddress?: Address = undefined
    @JsonProperty("client", Client, true)
    client?: Client = undefined
    @JsonProperty("company", Company, true)
    company?: Company = undefined
    @JsonProperty("shippingAddress", Address, true)
    shippingAddress?: Address = undefined
    @JsonProperty("budget", Boolean, true)
    budget?: boolean = undefined
    @JsonProperty("creationRate", Number, true)
    creationRate?: number = undefined
    @JsonProperty("total", String, true)
    total?: string = undefined
    @JsonProperty("totalPvp", String, true)
    totalPvp?: string = undefined
}

export interface OrderSelect {
    name: string,
    value: OrderType
}
