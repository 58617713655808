import {JsonObject, JsonProperty} from "json2typescript";
import Auditing from "@/models/Auditing";

@JsonObject("ClientBase")
export default class extends Auditing {

    @JsonProperty("id", Number, true)
    id?: number = undefined

    @JsonProperty("name", String, true)
    name?: string = undefined

    @JsonProperty("lastname", String, true)
    lastname?: string = undefined

    @JsonProperty("email", String, true)
    email?: string = undefined

    @JsonProperty("phone", String, true)
    phone?: string = undefined

    @JsonProperty("cif", String, true)
    cif?: string = undefined

    @JsonProperty("commercialName", String, true)
    commercialName?: string = undefined

    @JsonProperty("description", String, true)
    description?: string = undefined

    @JsonProperty("enable", Boolean, true)
    enable?: Boolean = undefined

}