import {JsonObject, JsonProperty} from "json2typescript";
import Phone from "@/models/Phone";
import Email from "@/models/Email";
import ShippingAddress from "@/models/ShippingAddress";
import VatType from "@/models/VatType";
import Zone from "@/models/Zone";

@JsonObject("Company")
export default class Company {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("connectorId", String, true)
    connectorId?: string = undefined
    @JsonProperty("name", String, true)
    name?: string = undefined
    @JsonProperty("commercialName", String, true)
    commercialName?: string = undefined
    @JsonProperty("address", String, true)
    address?: string = undefined
    @JsonProperty("population", String, true)
    population?: string = undefined
    @JsonProperty("province", String, true)
    province?: string = undefined
    @JsonProperty("fax", String, true)
    fax?: string = undefined
    @JsonProperty("cif", String, true)
    cif?: string = undefined
    @JsonProperty("vatExtensive", Boolean, true)
    vatExtensive?: boolean = undefined
    @JsonProperty("withSurcharge", Boolean, true)
    withSurcharge?: boolean = undefined
    @JsonProperty("rateId", Number, true)
    rateId?: number = undefined
    @JsonProperty("active", Boolean, true)
    active?: boolean = undefined

    @JsonProperty("phones", [Phone], true)
    phones?: Phone[] = undefined
    @JsonProperty("emails", [Email], true)
    emails?: Email[] = undefined
    @JsonProperty("shippingAddress", [ShippingAddress], true)
    shippingAddress?: ShippingAddress[] = undefined
    @JsonProperty("vatType", VatType, true)
    vatType?: VatType = undefined
    @JsonProperty("zone", Zone, true)
    zone?: Zone = undefined

}